import { openDB, deleteDB, wrap, unwrap } from 'idb';

export const OfflineStorage = () => {

    const openCache = async () => {

        if (!window.indexedDB) {
            console.log(`Your browser doesn't support IndexedDB`);
            return;
        }

        const db = await openDB('Appcache', 1, {
            upgrade(db) {
                const store = db.createObjectStore('appcache', {
                    keyPath: 'id',
                    autoIncrement: false,
                });
            },
        });

        return db;
    };

    const isDBSet = async () => {
        const db = await openCache();
        const result = await db.get('appcache', '/api/db');

        console.log('isDBSet: ' + (result == undefined ? false : true));

        return result == undefined ? false : true;
    }

    const getCachedUrls = async () => {
        const db = await openCache();
        const result = await db.getAll('appcache');

        return result;
    };

    const isCached = async (url) => {
        const db = await openCache();
        const result = await db.get('appcache', url);

        return result == undefined ? false : true;
    };

    const cacheItem = async (url) => {
        const db = await openCache();

        const response = await fetch(url);
        const blob = await response.blob();
        
        await db.put('appcache', {
            id: url,
            data: blob
        });
    };

    const getJson = async () => {

        const db = await openCache();
        const result = await db.get('appcache', '/api/db');

        const { data } = await JSON.parse(result.value);
        return data;
    }

    const setJson = async (data) => {
        const db = await openCache();

        await db.put('appcache', {
            id: '/api/db',
            value: JSON.stringify(data),
        });

    }

    const setAuthRequest = async (data) => {
        const db = await openCache();

        await db.put('appcache', {
            id: '/api/user',
            value: JSON.stringify(data),
        });

    }

    const getAuthRequest = async () => {
        const db = await openCache();
        const result = await db.get('appcache', '/api/user');

        if (result === undefined) { return undefined }

        const data = JSON.parse(result.value);

        return data;
    }

    const deleteByUrl = async (url) => {
        const db = await openCache();

        await db.delete('appcache', url);
    }

    const deleteAuthRequest = async () => {
        const db = await openCache();

        await db.delete('appcache', '/api/user');
    }

    const getByName = async (name) => {
        const db = await openCache();
        const response = await db.get('appcache', name);
        const cacheBlob = response?.data;

        if (response === undefined) {
            console.log("No se encontró el archivo en caché: " + name);
            const serverResponse = await fetch(name);
            let blob = await serverResponse.blob();
            const file = new Blob(
                [blob],
                { type: 'image/jpeg' });

            const fileURL = URL.createObjectURL(file);
            console.log("Descargado de internet en lugar de caché: " + name);
            return fileURL;
        }

        const data = await cacheBlob;
        const fileURL = URL.createObjectURL(data);
        return fileURL;
    }

    return {
        isDBSet,
        getJson,
        setJson,
        setAuthRequest,
        getAuthRequest,
        deleteAuthRequest,
        getByName,
        cacheItem,
        isCached,
        getCachedUrls,
        deleteByUrl
    }
}
