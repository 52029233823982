import { BackgroundService } from '@/BackgroundService'
import AppContext from '@/hooks/AppContext'
import { useEffect, useState, useRef, useCallback } from 'react'
import { SSRKeycloakProvider, SSRCookies } from '@react-keycloak/ssr'
import cookie from 'cookie'
import Head from 'next/head'
import { useRouter } from 'next/router'

const App = ({ Component, pageProps, cookies }) => {
    const [backgroundServiceResult, setBackgroundServiceResult] = useState([
        false,
    ])

    const router = useRouter()

    useEffect(() => {
        // console.log('entering useEffect')
        let { redirectUriParam = '' } = router.query
        const { asPath } = router
        // console.log('router query', router.query)
        // console.log('router ', router)
        if (redirectUriParam != '') {
            console.log('Redirect route in _app.js: ' + redirectUriParam)
            if (process.browser) {
                // console.log('isBrowser')
                router.push(redirectUriParam)
            } else {
                // console.log('falla el process.browser')
            }
        } else {
            // console.log('asPath', asPath)
            // console.log('match', /.*redirectUriParam.*/.test(asPath))
            if (/.*redirectUriParam.*/.test(asPath)) {
                const route = asPath.split('?redirectUriParam=')[1]
                console.log('route', route)
                router.push(route)
            }
        }
    }, [])

    useEffect(() => {
        try {
            const handleRouteChange = url => {
                try {
                    ga.pageview(url)
                } catch (ex) {}
            }

            router.events.on('routeChangeComplete', handleRouteChange)

            return () => {
                router.events.off('routeChangeComplete', handleRouteChange)
            }
        } catch (ex) {}
    }, [router.events])

    const keycloakCfg = {
        realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM,
        url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
        clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID,
    }

    return (
        <>
            <Head>
                <title>Faes Farma Vademécum</title>
            </Head>

            <SSRKeycloakProvider
                keycloakConfig={keycloakCfg}
                persistor={SSRCookies(cookies)}>
                <AppContext.Provider
                    value={{
                        backgroundServiceResult,
                        setBackgroundServiceResult,
                    }}>
                    <BackgroundService />
                    <Component {...pageProps} />
                </AppContext.Provider>
            </SSRKeycloakProvider>
        </>
    )
}

function parseCookies(req) {
    if (!req || !req.headers) {
        return {}
    }
    return cookie.parse(req.headers.cookie || '')
}

App.getInitialProps = async context => {
    return {
        cookies: parseCookies(context?.ctx?.req),
    }
}

export default App
