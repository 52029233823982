import useSWR from 'swr'
import axios from '@/lib/axios'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { OfflineStorage } from '@/OfflineStorage';

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
    const router = useRouter()

    const { isDBSet, setAuthRequest, getAuthRequest, deleteAuthRequest } = OfflineStorage()
    const { data: user, error, revalidate, mutate } = useSWR('/api/user', () => {

        const request = getAuthRequest().then(res => {
            if (res === undefined) {
                console.log('Usuario no logeado en caché')

                const loginReq = axios
                    .get('/api/user')
                    .then(res => res.data)
                    .catch(error => {
                        if (error.response.status == 401 && router.pathname !== '/') {
                            console.log('error from /api/user useSWR: ' + error)
                            console.log('Unauthorized, redirecting to /')
                            router.push('/')
                            return;
                        }
                    });

                    loginReq.then(data => {
                        if (data != undefined) {
                            console.log("Usuario logeado en caché: " + data)
                            setAuthRequest(data).then(() => {
                                revalidate();
                            });
                        }
                    });
                
            }

            return res
        });

        return request;
    }
        ,
        {
            //https://github.com/vercel/swr/issues/450#issuecomment-1068411634
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateOnMount: true, // If false, undefined data gets cached against the key.
            dedupingInterval: 3_600_000, // dont duplicate a request w/ same key for 1hr
        }
    )

    const csrf = () => axios.get('/sanctum/csrf-cookie')

    const register = async ({ setErrors, ...props }) => {
        await csrf()

        setErrors([])

        axios
            .post('/register', props)
            .then(() => revalidate())
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const login = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)
        let url = '/login'

        if (props.kcToken) {
            url = '/login/kc'
        }

        axios
            .post(url, props)
            .then(() => revalidate())
            .catch(error => {
                if (error.response.status !== 422) return;

                setErrors(Object.values(error.response.data.errors).flat())
            })

        if (!user) return false;
    }

    const forgotPassword = async ({ setErrors, setStatus, email }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/forgot-password', { email })
            .then(response => setStatus(response.data.status))
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resetPassword = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/reset-password', { token: router.query.token, ...props })
            .then(response => router.push('/auth/login?reset=' + btoa(response.data.status)))
            .catch(error => {
                if (error.response.status != 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    const resendEmailVerification = ({ setStatus }) => {
        axios
            .post('/email/verification-notification')
            .then(response => setStatus(response.data.status))
    }

    const logout = async () => {

        deleteAuthRequest()

        if (!error) {
            try {
                await axios.post('/logout').catch(error => {
                    console.log(error)
                });
            } catch (error) {
                console.log(error)
            }
        }

        revalidate()
        mutate('/api/user', null)
        mutate(null)


        if (process.env.NODE_ENV === 'production') {
            window.location.pathname = '/frontend'
        } else {
            window.location.pathname = '/'
        }

    }

    const registerPharmacist = async ({ setErrors, setStatus, ...props }) => {
        await csrf()

        setErrors([])
        setStatus(null)

        axios
            .post('/registerPharmacist', props)
            .then(response => setStatus(response.data.status))
            .catch(error => {
                if (error.response.status !== 422) throw error

                setErrors(Object.values(error.response.data.errors).flat())
            })
    }

    useEffect(async () => {
        let isDBSetBool = await isDBSet();
        // console.log(user)

        if (middleware === 'guest' && redirectIfAuthenticated && user) {
            console.log('redirect')
            if (!isDBSetBool) {
                router.push('/downloadScreen')
            } else {
                let { redirectUriParam = "" } = router.query;
                if (redirectUriParam == "") {
                    router.push(redirectIfAuthenticated)
                } else {
                    console.log("Redirecting from auth to route " + redirectUriParam)
                    router.push(redirectUriParam)
                }
            }
        }

        if (middleware === 'auth' && error) {
            console.log('Logged out: ', error)
            logout()
        }

        if (middleware == 'authCheck' && navigator.onLine && user) {
            if (user) {
                axios.get('/api/user').then(res => {
                    if (res.data.status == 'disabled') {
                        logout();
                    }
                })
            }
        }
    }, [user, error])

    return {
        user,
        register,
        login,
        forgotPassword,
        resetPassword,
        resendEmailVerification,
        logout,
        registerPharmacist,
    }
}
