import { useEffect, useContext } from "react"
import { OfflineStorage } from '@/OfflineStorage';
import AppContext from '@/hooks/AppContext'
import { api } from "@/hooks/api"

//https://stackoverflow.com/questions/57448790/background-service-using-react-hooks

export const BackgroundService = () => {

    const { getLatestSyncInfo, checkForDBUpdate } = api()
    const { isDBSet, getJson } = OfflineStorage()

    useEffect(() => {

        const intervalId = setInterval(async () => {
            if (window.location.pathname == '/') { return }
            if (window.location.pathname.includes('auth')) { return }
            if (window.location.pathname.includes('downloadScreen')) { return }

            const isDBSetBool = await isDBSet();

            if (!isDBSetBool) return
            if (!navigator.onLine) return

            checkForDBUpdate();
        }, 1800000) // 30 minutes

        return () => clearInterval(intervalId);
    }, [])

    return (
        <></>
    )
}

export default BackgroundService;