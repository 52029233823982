import axios from '@/lib/axios';
import { OfflineStorage } from '@/OfflineStorage';
import AppContext from '@/hooks/AppContext'
import { useContext } from "react"
import { useAuth } from '@/hooks/auth'
import { ConstructionOutlined } from '@mui/icons-material';

export const api = () => {

    const { logout } = useAuth();

    const { getJson, getByName } = OfflineStorage();
    const appContext = useContext(AppContext);

    const productTypes = async (productTypeId) => {
        let { productTypes } = await getJson()

        for (let i = 0; i < productTypes.length; i++) {
            productTypes[i].image_url = await getByName(productTypes[i].image_url)
        }

        if (productTypeId != undefined) {
            productTypes = productTypes.filter(productType => productType.id == productTypeId)[0]
        }

        return productTypes
    }

    const categories = async (productTypeId) => {
        let { categories } = await getJson()

        for (let i = 0; i < categories.length; i++) {
            categories[i].image_url = await getByName(categories[i].image_url)
        }

        return categories = categories.filter(
            category => category.prescriptionTypes.filter(prescriptionType => prescriptionType.id == productTypeId).length > 0
        )
    }

    const categoryProducts = async (categoryId) => {
        let { products } = await getJson()
        
        for (let i = 0; i < products.length; i++) {
            
            if (products[i].grid_image === undefined || products[i].grid_image === '') {
                continue;
            }

            products[i].grid_image = await getByName(products[i].grid_image)
        }

        products = products.filter(product => {
            return product.categories.filter(category => category.id == categoryId).length > 0
        });
  
        products.sort((a, b) => {
            if (a.priority == 999 && b.priority == 999) {
                return a.name > b.name ? 1 : -1
            } else {
                return a.priority - b.priority
            }
        });

        return products;
    }


    const category = async (id) => {
        let { categories } = await getJson()

        return categories.find(category => {
            return category.id == id
        })
    }

    const products = async () => {
        let { products } = await getJson()

        for (let i = 0; i < products.length; i++) {
            if (products[i].featured_category_image) {
                products[i].featured_category_image = await getByName(products[i].featured_category_image)
            }
            if (products[i].featured_product_type_image) {
                products[i].featured_product_type_image = await getByName(products[i].featured_product_type_image)
            }
        }

        return products
    }

    const product = async (slug) => {
        let { products } = await getJson()

        const product = products.find(product => {
            return product.slug == slug
        });

        return product;
    }

    const productById = async (id) => {
        let { products } = await getJson()

        return products.find(product => {
            return product.id == id
        })
    }

    const productPresentations = async (productId) => {
        let { presentations } = await getJson()

        let presentationsFiltered = presentations.filter(presentation => {
            return presentation.product_id == productId
        });

        for (let i = 0; i < presentationsFiltered.length; i++) {
            presentationsFiltered[i].imageurl = await getByName(presentationsFiltered[i].imageurl)
        }

        presentationsFiltered.sort((a, b) => {
            if (a.priority == 999 && b.priority == 999) {
                return a.name > b.name ? 1 : -1
            } else {
                return a.priority - b.priority
            }
        });

        return presentationsFiltered;
    }

    const presentation = async (id) => {
        let { presentations } = await getJson()

        if (id === undefined) {
            return presentations;
        }

        const presentation = presentations.find(presentation => {
            return presentation.id == id
        });

        presentation.imageurl = await getByName(presentation.imageurl);

        return presentation;
    }

    const productRangePresentations = async (id) => {
        let { presentations } = await getJson()

        let presentationsFiltered = presentations.filter(presentation => {
            return presentation.productrange_id == id
        });

        for (let i = 0; i < presentationsFiltered.length; i++) {
            presentationsFiltered[i].imageurl = await getByName(presentationsFiltered[i].imageurl)
        }

        presentationsFiltered.sort((a, b) => {
            if (a.priority == 999 && b.priority == 999) {
                return a.name > b.name ? 1 : -1
            } else {
                return a.priority - b.priority
            }
        });

        return presentationsFiltered;
    }

    const productRanges = async () => {
        let { productRanges } = await getJson()

        return productRanges
    }

    const productRangeById = async (id) => {
        let { productRanges } = await getJson()

        return productRanges.find(productRange => {
            return productRange.id == id
        })
    }

    const productRangeOfProduct = async (productId, categoryId) => {
        let { productRanges } = await getJson()
        
        let productRangesFiltered = productRanges.filter(productRange => {
            return productRange.product_id == productId && productRange.category_id == categoryId
        });

        for (let i = 0; i < productRangesFiltered.length; i++) {
            productRangesFiltered[i].image_url = await getByName(productRangesFiltered[i].image_url)
        }

        productRangesFiltered.sort((a, b) => {
            if (a.priority == 999 && b.priority == 999) {
                return a.name > b.name ? 1 : -1
            } else {
                return a.priority - b.priority
            }
        });

        return productRangesFiltered;
    }

    const getDB = async () => {
        return axios.get("/api/db");
    }

    const getImageUrls = async () => {
        return axios.get("/api/imageUrls");
    }

    const getPDFUrls = async () => {
        return axios.get("/api/pdfUrls");
    }

    const getLatestSyncInfo = async () => {
        try {
            const response = await axios.get("/api/latestApiSync");
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                console.error("Error de autenticación no válida:", error.response.data);
            } else {
                throw error;
            }
        }
    };

    const searchPerformed = async (data) => {
        return axios.post("/api/searchPerformed", data);
    }

    const checkForDBUpdate = async () => {
        const serverResponse = await getLatestSyncInfo();

        if (serverResponse === undefined) {
            logout();
            return;
        }
        
        const BDLatestSyncDate = serverResponse.data.data.created_at;
        console.log("Fecha sync Back: " + BDLatestSyncDate);

        const localJson = await getJson();
        const localSyncDate = localJson.sync_date;
        console.log('Fecha sync Local: ' + localSyncDate);

        if (localSyncDate != BDLatestSyncDate) {
            console.log("Actualización de datos necesaria")
            appContext.setBackgroundServiceResult([true, serverResponse.data.data.modified_entities]);
        } else {
            console.log("No hay actualización de datos necesaria")
            appContext.setBackgroundServiceResult([false]);
        }
    }

    return {
        productTypes,
        categories,
        categoryProducts,
        category,
        products,
        product,
        productById,
        productPresentations,
        productRanges,
        presentation,
        productRangeOfProduct,
        productRangePresentations,
        productRangeById,
        getImageUrls,
        getPDFUrls,
        getLatestSyncInfo,
        checkForDBUpdate,
        getDB,
        searchPerformed
    }
}